import moment from "moment"
import {FormID} from "../interfaces"

const formatDateMonthDayYear = (date) => moment(date).format(" MMMM D [,] YYYY")

const formatStandarUSDate = (date) => moment(date).utc().format("MM/DD/YYYY")

const MANHATTAN = {
    id: 5,
    name: "Manhattan",
    code: "C5729"
}

const CALIFORNIA_FORMS_PDF: FormID[] = [
    "enrollment_application",
    "arbitration_agreement",
    "enrollment_agreement",
    "school_performance_fact",
    "opt_outs",
    "distance_education_disclosure",
    "checklist_page",
    "criminal_disclosure",
    "ferpa_release_form",
    "overtime_disclosure",
    "third_party_vendors_disclosure"
]

const MANHATTAN_FORMS_PDF: FormID[] = [
    "enrollment_application",
    "arbitration_agreement",
    "enrollment_agreement",
    "opt_outs",
    "distance_education_disclosure",
    "checklist_page",
    "criminal_disclosure",
    "ferpa_release_form",
    "overtime_disclosure",
    "third_party_vendors_disclosure",
    "apprentice_application",
    "student_reference_form"
]

const REFILL_FORMS: FormID[] = [
    "enrollment_agreement",
    "opt_outs",
    // "application_upload_docs",
    "application_upload_disability_doc"
]

const removeJsonDataForms = (currentJsonDataForms) => {
    return Object.keys(currentJsonDataForms)
        .filter((key) => !REFILL_FORMS.includes(key as FormID))
        .reduce((obj, key) => {
            obj[key] = currentJsonDataForms[key]
            return obj
        }, {})
}
const counterForms = (currentJsonDataForms, constantsForms) => {
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    const filledForms = constantsForms.length
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (constantsForms.includes(elementKey as FormID)) counter += 1
    }
    return counter === filledForms ? false : true
}

const isEnableSubmitButton = (currentJsonDataForms, campus, campusesInfo, program) => {
    if (campus && campusesInfo.length > 0) {
        const [, , , {id: manhattanCampusId}] = campusesInfo
        const [campusSelectedId, ,] = getPartsByDelimiter(campus)
        if (program) {
            const [programSelectedId, ,] = getPartsByDelimiter(program)
            // console.log(programSelectedId, "PROGRAM ID")
            if (+campusSelectedId !== manhattanCampusId) {
                if (programSelectedId == PROGRAM_IDS.makeup_artistry.id) {
                    const FREELANCE_FORM = "freelance_selfemployed_disclosure"
                    let CALIFORNIA_FORMS_PLUS_MAKEUP = [...CALIFORNIA_FORMS_PDF, FREELANCE_FORM]
                    // console.log(CALIFORNIA_FORMS_PLUS_MAKEUP, "IS MAKEUP")
                    return counterForms(currentJsonDataForms, CALIFORNIA_FORMS_PLUS_MAKEUP)
                } else {
                    // console.log(CALIFORNIA_FORMS_PDF, "NO MAKEUP")
                    return counterForms(currentJsonDataForms, CALIFORNIA_FORMS_PDF)
                }
            } else {
                // console.log(MANHATTAN_FORMS_PDF, "NO CALIFORNIA")
                return counterForms(currentJsonDataForms, MANHATTAN_FORMS_PDF)
            }
        }
    }
    return true
}

const currentSizeForm = (currentJsonDataForms, constantForms) => {
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (constantForms.includes(elementKey as FormID)) counter += 1
    }
    return counter
}

const ACADEMIC_ADJUSMENT_FORM = "academic_adjustment_form"

const hasAllDisabilityDocumentsRequired = (currentJsonDataForms) => {
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    return jsonFormsKeys.includes(ACADEMIC_ADJUSMENT_FORM)
}

const APPLICATION_CAREER_INFORMATION: FormID = "application_career_information"
const ENROLLMENT_APPLICATION: FormID = "enrollment_application"
const UPLOAD_DISABILITY_SUPPORTING_DOCUMENTS: FormID = "application_upload_disability_doc"
const ENROLLMENT_AGREEMENT: FormID = "enrollment_agreement"
const SCHOOLPERFORMANCE_FACT: FormID = "school_performance_fact"
const OPT_OUTS: FormID = "opt_outs"
const APPLICATION_READY_TO_ENROLL: FormID = "application_ready_to_enroll"

const ALL_FORMS_IN_TABS = [
    "application_career_information",
    "enrollment_application",
    "academic_adjustment_form",
    "application_upload_disability_doc",
    "application_ready_to_enroll",
    "opt_outs",
    "enrollment_agreement",
    "arbitration_agreement",
    "school_performance_fact",
    "distance_education_disclosure",
    "freelance_selfemployed_disclosure",
    "checklist_page",
    "criminal_disclosure",
    "ferpa_release_form",
    "overtime_disclosure",
    "third_party_vendors_disclosure",
    "apprentice_application",
    "student_reference_form",
    "application_upload_docs",
    "submission"
]

const YesNo = {
    yes: "yes",
    no: "no"
}

const refreshSignaturesOrInitials = (json_data, form_ids, ids) => {
    // console.log("@@@@@@@@@@@ refreshSignaturesOrInitials Web:", {
    //     form_ids,
    //     ids
    // })
    if (json_data.forms) {
        for (let j = 0; j < form_ids.length; j++) {
            const form = form_ids[j]
            delete json_data.forms[form]
        }
    }
    for (let i = 0; i < ids.length; i++) {
        const id = ids[i]
        delete json_data[id]
        delete json_data[`${id}_date`]
    }
}
const REFILL_FORMS_IDS = {
    withNoOnchange: {
        forms: [],
        ids: []
    },
    enrollment_application: {
        forms: ["enrollment_application"],
        ids: ["student_signature_ea"],
        conditionId: "isApplicationSubmitted"
    },
    arbitration_agreement: {
        forms: ["arbitration_agreement"],
        ids: ["student_signature_ab"]
    },
    enrollment_agreement: {
        forms: ["enrollment_agreement"],
        ids: ["student_signature", "student_signature_catalog", "student_signature_performance"]
    },
    school_performance_fact: {
        forms: ["school_performance_fact"],
        ids: [
            // "page_1_initial",
            // "page_2_initial",
            // "page_3_examination_initial",
            // "page_3_salary_initial",
            // "page_3_self_employment_initial",
            // "page_4_educational_initial",
            // "page_4_federal_initial",
            "student_signature_spf"
        ]
    },
    opt_outs: {
        forms: ["opt_outs"],
        ids: ["student_signature_opt_out"]
    },
    distance_education_disclosure: {
        forms: ["distance_education_disclosure"],
        ids: ["distance_education_signature"]
    },
    freelance_selfemployed_disclosure: {
        forms: ["freelance_selfemployed_disclosure"],
        ids: ["student_signature_fsd", "student_signature_fsd_2"]
    },
    checklist_page: {
        forms: ["checklist_page"],
        ids: ["student_signature_cp"]
    },
    academic_adjustment_form: {
        forms: ["academic_adjustment_form"],
        ids: ["student_signature_academic_adjustment"]
    },
    application_upload_docs: {
        forms: ["application_upload_docs"],
        ids: []
    },
    criminal_disclosure: {
        forms: ["criminal_disclosure"],
        ids: ["student_signature_lrd"]
    },
    ferpa_release_form: {
        forms: ["ferpa_release_form"],
        ids: ["student_signature_ferpa_release"]
    },
    overtime_disclosure: {
        forms: ["overtime_disclosure"],
        ids: ["student_signature_overtime"]
    },
    third_party_vendors_disclosure: {
        forms: ["third_party_vendors_disclosure"],
        ids: ["student_signature_third_party_vendors"]
    },
    apprentice_application: {
        forms: ["apprentice_application"],
        ids: []
    },
    student_reference_form: {
        forms: ["student_reference_form"],
        ids: []
    }
}

const SAVE_CHANGES_MESSAGE = "Page was not saved. Please Press Save & Continue to keep the changes"

const stringErrors = (errors) =>
    Object.keys(errors)
        .map((err) => `• ${err}\n`)
        .join("")

const DELIMITER = "<<@>>"

const getPartsByDelimiter = (value) => {
    return value.split(DELIMITER)
}

const PROGRAM_IDS = {
    esthetics_102_hybrid: {
        id: 259,
        code: "EST 102 HYB",
        name: "Esthetics 102 Hybrid"
    },
    cosmetology_hybrid: {
        id: 258,
        code: "COS HYB",
        name: "Cosmetology Hybrid"
    },
    cosmetology_102_Hybrid: {
        id: 257,
        code: "COS 102 HYB",
        name: "Cosmetology 102 Hybrid"
    },
    esthetics_hybrid: {
        id: 256,
        code: "EST HYB",
        name: "Esthetics Hybrid"
    },
    barbering: {
        id: 111,
        code: "124904",
        name: "Barbering"
    },
    spa_nail_technician: {
        id: 31,
        code: "45",
        name: "Spa Nail Technician"
    },
    esthetics: {
        id: 28,
        code: "40",
        name: "Esthetics"
    },
    esthetics_102: {
        id: 21,
        code: "29",
        name: "Esthetics 102"
    },
    cosmetology_102: {
        id: 20,
        code: "28",
        name: "Cosmetology 102"
    },
    cosmetology: {
        id: 14,
        code: "12",
        name: "Cosmetology"
    },
    esthetics_103_hybrid: {
        id: 255,
        code: "EST 103 HYB",
        name: "Esthetics 103 Hybrid"
    },
    advanced_cosmetology_hybrid: {
        id: 254,
        code: "ADV COS HYB",
        name: "Advanced Cosmetology Hybrid"
    },
    advanced_cosmetology: {
        id: 253,
        code: "ADV COS",
        name: "Advanced Cosmetology"
    },
    advanced_barbering_hybrid: {
        id: 252,
        code: "ADV BAR HYB",
        name: "Advanced Barbering Hybrid"
    },
    barbering_foundations_hybrid: {
        id: 251,
        code: "BAR FOU HYB",
        name: "Barbering Foundations Hybrid"
    },
    cosmetology_foundations_hybrid: {
        id: 250,
        code: "COS FOU HYB",
        name: "Cosmetology Foundations Hybrid"
    },
    barbering_cosmetology_hybrid: {
        id: 249,
        code: "BAR COS HYB",
        name: "Barbering Cosmetology Hybrid"
    },
    advanced_barbering: {
        id: 248,
        code: "ADV BAR",
        name: "Advanced Barbering"
    },
    cosmetology_foundation: {
        id: 183,
        code: "18285740",
        name: "Cosmetology Foundation"
    },
    barbering_foundation: {
        id: 182,
        code: "18285739",
        name: "Barbering Foundation"
    },
    barbering_cosmetology: {
        id: 158,
        code: "17825579",
        name: "Barbering-Cosmetology"
    },
    esthetics_103: {
        id: 113,
        code: "173061",
        name: "Esthetics 103"
    },
    makeup_artistry: {
        id: 152,
        code: "7509486",
        name: "Master Makeup Artistry"
    }
}

export {
    formatDateMonthDayYear,
    formatStandarUSDate,
    removeJsonDataForms,
    isEnableSubmitButton,
    currentSizeForm,
    CALIFORNIA_FORMS_PDF,
    MANHATTAN_FORMS_PDF,
    MANHATTAN,
    APPLICATION_CAREER_INFORMATION,
    UPLOAD_DISABILITY_SUPPORTING_DOCUMENTS,
    ENROLLMENT_APPLICATION,
    ENROLLMENT_AGREEMENT,
    SCHOOLPERFORMANCE_FACT,
    OPT_OUTS,
    APPLICATION_READY_TO_ENROLL,
    hasAllDisabilityDocumentsRequired,
    ALL_FORMS_IN_TABS,
    YesNo,
    refreshSignaturesOrInitials,
    REFILL_FORMS_IDS,
    SAVE_CHANGES_MESSAGE,
    stringErrors,
    DELIMITER,
    getPartsByDelimiter,
    PROGRAM_IDS
}
